@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  @apply font-inter !important;
}

.progress-bar {
  @apply h-1 rounded;
}

.progress-bar::-webkit-progress-bar {
  @apply bg-secondary-400; 
}

.progress-bar::-webkit-progress-value {
  @apply bg-blue-600; 
}

.progress-bar::-moz-progress-bar {
  @apply bg-blue-600; 
}


/* loader.css */
.loader {
  @apply flex justify-center gap-5;
}

.dot {
  @apply w-4 h-4 bg-gray-500 rounded-full;
  animation: pulse 1.5s infinite;
}

.dot:nth-child(1) {
  animation-delay: 0s;
}

.dot:nth-child(2) {
  animation-delay: 0.5s;
}

.dot:nth-child(3) {
  animation-delay: 1s;
}
@keyframes pulse {
  0% {
    @apply opacity-0;
  }
  50% {
    @apply opacity-100;
  }
  100% {
    @apply opacity-0;
  }
}
.scrollbar-hidden {
  /* Hide scrollbar for Chrome, Safari and Opera */
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.scrollbar-hidden::-webkit-scrollbar {
  display: none;  /* Chrome, Safari and Opera */
}
